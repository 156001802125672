<template>
    <div>
        <div class="flex" :class="containerClass">
            <input
                :id="id"
                v-model="value"
                :aria-label="label ? undefined : name"
                :aria-invalid="isErrored ? 'true' : 'false'"
                :aria-errormessage="isErrored ? 'input-error-' + name : ''"
                type="checkbox"
                :name="name"
                :disabled="submitting"
                v-bind="$attrs"
                class="form-checkbox"
                :class="{
                    'input-is-errored': isErrored,
                    '!mr-3 -mt-[3px] !h-[23px] !w-[23px]  !border-grind/20':
                        theme === 'new',
                }"
            />

            <label
                v-if="label"
                :for="id"
                class="cursor-pointer leading-5"
                :class="[labelClass, { 'font-semibold': theme === 'default' }]"
            >
                {{ label }}
            </label>

            <label
                v-if="$slots.label"
                :for="id"
                class="cursor-pointer leading-5"
                :class="[labelClass, { 'font-semibold': theme === 'default' }]"
            >
                <slot name="label" />
            </label>
        </div>

        <InputErrors :errors="errors" :name="name" />

        <InputHelpText v-if="helpText">
            {{ helpText }}
        </InputHelpText>
    </div>
</template>

<script setup lang="ts">
import {
    defaultEmits,
    DefaultProps,
    defaultPropsDefaults,
} from '~/composables/useInput';
import InputErrors from './components/InputErrors.vue';
import InputHelpText from './components/InputHelpText.vue';

defineOptions({
    inheritAttrs: false,
});

const emit = defineEmits([...defaultEmits]);

interface Props extends Omit<DefaultProps, 'modelValue'> {
    modelValue: boolean;
    theme: 'default' | 'new';
}

const props = withDefaults(defineProps<Props>(), {
    ...defaultPropsDefaults,
    modelValue: false,
    labelClass: '-my-0.5',
    theme: 'default',
});

const { id, value, errors, isErrored } = useInput(props, emit);
</script>
